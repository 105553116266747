<template>
  <div class="card-body">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      loading-text="Malumot yuklanmoqda..."
      :items="allSkladList"
      single-select
      @click:row="rowClick"
      item-key="id"
      hide-default-footer
      no-data-text="Malumot kiritilmagan"
      class="elevation-1 row-pointer"
    >
      <template v-slot:[`item.action`]="{ item }">
        <TechMonitorAction :index="item"></TechMonitorAction>
      </template>

      <template v-slot:[`item.expires`]="{ item }">
        {{ item.expires | formatDate }}
      </template>

      <template v-slot:[`item.monitored`]="{ item }">
        {{ item.monitored | formatDate }}
      </template>
    </v-data-table>
    <div class="text-left mt-5">
      <v-pagination
        v-model="currentPage"
        :total-visible="7"
        @input="getPostData(currentPage)"
        :length="Math.ceil(getCount.count / 10)"
      ></v-pagination>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import TechMonitorAction from './TechMonitorAction'
export default {
  components: { TechMonitorAction },
  data: () => ({
    perPage: 10,
    counter: 0, // count the clicks
    timer: null, // Needs to be specified here so it can be accessed on both clicks
    selectedId: -1,
    currentPage: 1
  }),
  created() {
    this.currentPage = parseInt(this.$route.params.id, 10)
    this.getPostData(this.currentPage)
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentPage = parseInt(id, 10)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.MONITORED_BY'),
          value: 'monitored_by'
        },
        {
          text: this.$t('TABLE_HEADER.NUMBER'),
          value: 'reg_number'
        },
        {
          text: this.$t('TABLE_HEADER.MONITORED'),
          value: 'monitored'
        },
        {
          text: this.$t('TABLE_HEADER.EXPIRES'),
          value: 'expires'
        },

        {
          text: this.$t('TABLE_HEADER.TRUCK_NUMBER'),
          value: 'truck_info.truck_number'
        },
        {
          value: 'action',
          text: '',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getCount() {
      return this.$store.state.requests.techMonitor
    },
    allSkladList() {
      var data = []
      data = this.$store.state.requests.techMonitor.results

      return data
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0

        this.$router.push('/techmonitor/details/' + item.id + '/')

        row.select(false)
      }
    },
    getPostData(value) {
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          id: value,
          payload: this.$store.state.requests.filterData.data
        }
        this.$store.dispatch('contractsSearchPerPages', asd)
        if (this.$route.path !== '/allcontracts/' + value) {
          this.$router.push('/allcontracts/' + value)
        }
      } else {
        this.$store.dispatch('getTechMonitor', value)
        if (this.$route.path !== '/techmonitor/' + value) {
          this.$router.push('/techmonitor/' + value)
        }
      }
    }
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
